import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import selfie from './images/face1.jpg';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import Project from './project';
import List from './list';
import Contact from './contact';
import Testimonial from './testimonial';

function App() {
  const projects = [
      {
          name: 'Campus React',
          image: 'campus-react.png',
          url: 'http://shawncontant.com:5021',
          description: 'Basic showcase of my React and Express skills',
          github: 'campus-react'
      }, {
          name: 'Genealogy Mapper',
          image: 'genealogy.png',
          url: 'http://shawncontant.com:5030',
          description: 'Plotting my family tree according to Ancestry.com on a Google map',
          github: 'genealogy'
      }, {
          name: 'Procedural Content Generation Wiki',
          image: 'pcg-wiki.png',
          url: 'https://procedural-content-generation.fandom.com/wiki/Procedural_Content_Generation_Wiki',
          description: 'Wiki I made with some procedural content generation algorithms and examples',
          github: 'procedural-content-generation-wiki'
      }
  ];
  const lists = [
      {
          title: 'Front End',
          list: ['html', 'css', 'javascript', 'react', 'angularjs', 'backbone', 'marionette', 'sass/scss', 'less', 'jquery']
      }, {
          title: 'Back End',
          list: ['php', 'perl', 'sql', 'express', 'nodejs', 'codeigniter', 'sqlite']
      }, {
          title: 'Amazon',
          list: ['s3', 'ec2', 'athena', 'glue', 'lake formation', 'personalize', 'route 53', 'iam']
      }, {
          title: 'Other',
          list: ['python', 'json', 'git', 'api integration', 'vim', 'nginx', 'npm', 'yarn', 'linux']
      }
  ]
  const testimonials = [
  ];
  return (
    <div className="App">
        <Container>
            <h1>Hi, I'm Shawn.</h1>
            <Row>
                <Col> I'm a full stack web developer (plus some Python!) based in Manhattan, Kansas. I specialize in writing functional code that meets business 
                needs. My diverse skill set ranges from plain HTML + Javascript + CSS, all the way up to frameworks like React. I'm also familiar with Amazon AWS services. I 
                am a problem solver and learner through-and-through, and am interested in knowing how I can help you solve your latest business technical challenges.<br />
                <a href='https://github.com/shawnco'>GitHub</a> | <a href='https://www.linkedin.com/in/shawn-contant-a4847566/'>LinkedIn</a>
                </Col>
                <Col><img src={selfie} id='selfie' /></Col>
            </Row>
            <h1>Projects</h1>
            <Row>
                {projects.map(project => <Project {...project} />)}                
            </Row>
            <h1>Skill Set</h1>
            <Row>
                {lists.map(list => <List {...list} />)}
            </Row>
            {testimonials.length > 0 ? <h1>Testimonials</h1> : null}
            {testimonials.map(t => <Testimonial {...t} />)}
            <h1>Contact</h1>
            <Row>
                <Col>I can be contacted at <strong>shawnc366 (at) gmail.com</strong></Col>
            </Row>
        </Container>
    
    </div>
  );
}

export default App;
