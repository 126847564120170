import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subject: '',
            message: ''
        };
        this.updateState = this.updateState.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    updateState(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    sendEmail(){}

    render() {
        const {email, subject, message} = this.state;
        return <form id='contact-form'>
            <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input type='text' className='form-control' name='email' value={email} onChange={this.updateState} /><br />

                <label htmlFor='subject'>Subject</label>
                <input type='text' className='form-control' name='subject' value={subject} onChange={this.updateState} /><br />

                <label htmlFor='message'>Message</label>
                <textarea rows='5' className='form-control' name='message' value={message} onChange={this.updateState} /><br />
                
                <Button variant='primary' onClick={this.sendEmail}>Send</Button>
            </div>
        </form>
    }
}

export default Contact;