import React from 'react';
import {Col} from 'react-bootstrap';

const List = (props) => {
    const {title, list} = props;
    return <Col>
        <h2>{title}</h2>
        <ul>
            {list.sort().map(elem => <li>{elem}</li>)}
        </ul>
    </Col>
}

export default List;