import React from 'react';
import {Row, Col} from 'react-bootstrap';

const Testimonial = (props) => {
    const {message, person, link} = props;
    return <Row>
        <Col><span className='testimonial'>{message}</span><br />
        - {link ? <a href={link}>{person}</a> : person }
        </Col>
    </Row>
}

export default Testimonial;