import React, {Component, Fragment} from 'react';
import {Col} from 'react-bootstrap';

const Project = (props) => {
    const {name, image, url, description, github} = props;
    const screenshot = `./images/${image}`;
    console.log(screenshot)
    return <Col sm={3}>
        <img src={screenshot} className='project-image' /><br />
        <a href={url} target='_blank'>{name}</a><br />
        {description}<br />
        <a href={`https://github.com/shawnco/${github}`}>GitHub Repo</a>
    </Col>
}

export default Project;